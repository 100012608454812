import React, { FC } from 'react';

import { ThreadsEventButton } from 'src/sections/ThreadsEvent';

interface Props {
  onClick?: (event) => void;
}

const WatchNowButton: FC<Props> = ({ onClick }) => {
  return (
    <ThreadsEventButton
      onClick={onClick}
      color='black'
      size='small'
      sx={{
        width: '156px',
        padding: { xs: '1.2rem' },
        fontSize: { xs: '1.6rem', sm: '1.8rem' },
        letterSpacing: '0.008rem',
        border: '1px solid white',
        '&:hover, :focus, :active': {
          color: 'black',
          border: '1px solid black',
          backgroundColor: 'white',
        },
      }}
    >
      Watch Now
    </ThreadsEventButton>
  );
};

export default WatchNowButton;
