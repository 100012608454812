import React, { FC } from 'react';
import {
  FeaturesRowItemProps,
  FeaturesRowProps,
  FeaturesProps,
} from './Features.types';

import { Section, Column, Box, Row } from 'src/components-v2/Layout';
import { Typography } from 'src/components-v2/DataDisplay';
import { Wrapper } from 'src/containers';

const FeatureRowItem: FC<FeaturesRowItemProps> = ({
  heading,
  description,
  index,
}) => {
  return (
    <Column
      xs={22}
      xsOffset={0}
      sm={10}
      smOffset={index === 0 ? 0 : 4}
      xl={8}
      xlOffset={index === 0 ? 4 : 3}
    >
      <Box sx={{ mb: '5rem' }}>
        <Section component='header'>
          <Typography
            variant='p'
            component='h3'
            theme='post-threads-features'
            sx={{ fontWeight: '800' }}
          >
            {heading}
          </Typography>
        </Section>
        <Typography
          variant='p'
          theme='post-threads-features'
          sx={{
            fontSize: '1.4rem',
            lineHeight: '2rem',
            letterSpacing: '0.022rem',
          }}
        >
          <span dangerouslySetInnerHTML={{ __html: description }} />
        </Typography>
      </Box>
    </Column>
  );
};

const FeaturesRow: FC<FeaturesRowProps> = ({ row }) => {
  return (
    <Row>
      {row.map((rowItem, i) => {
        return (
          <FeatureRowItem
            key={`feature-row-item-${i}`}
            heading={rowItem.heading}
            description={rowItem.description}
            index={i}
          />
        );
      })}
    </Row>
  );
};

const Features: FC<FeaturesProps> = ({ heading, description, rows }) => {
  return (
    <Wrapper>
      <Column xs={22} xsOffset={1} xl={8} xlOffset={1}>
        <Section
          component='header'
          sx={{ mb: { xs: '5rem', sm: '7rem', md: '10rem', xl: '5rem' } }}
        >
          <Typography
            variant='h2'
            theme='post-threads-features'
            sx={{
              mb: { xs: '3.2rem', sm: '2.3rem' },
            }}
          >
            {heading}
          </Typography>
          <Typography variant='p' theme='post-threads-features'>
            {description}
          </Typography>
        </Section>
      </Column>
      <Column xs={22} xsOffset={1} xl={14}>
        {rows.map((row, i) => {
          return <FeaturesRow row={row} key={`feature-row-${i}`} />;
        })}
      </Column>
    </Wrapper>
  );
};

export default Features;
