import React from 'react';
import { ThreadsEventButtonProps } from './ThreadsEventButton.types';
import styled from 'threads5/styles/styled';
import { Button as ButtonUnstyled } from '@mui/base/Button';

// components
import { useTrack, trackingActions } from 'src/contexts/AnalyticsContext';

const StyledButton = styled(ButtonUnstyled)({});

const buttonSizes = {
  small: {
    width: { xs: 300, md: 190 },
    fontSize: { xs: '1.7rem', md: '1.6rem' },
    lineHeight: { xs: '2.6rem', md: '2.4rem' },
    letterSpacing: { xs: '0.026rem', md: '0.024rem' },
    padding: { xs: '1.5rem', md: '0.9rem' },
    borderRadius: { xs: '37px', md: '44px' },
  },
  medium: {
    width: { xs: 304, md: 262 },
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    letterSpacing: '0.024rem',
    padding: { xs: '1.8rem 2.2rem', md: '1.2rem 2.2rem' },
    borderRadius: '44px',
  },
  large: {
    width: { xs: 300, md: 480 },
    fontSize: { xs: '1.8rem', md: '3rem' },
    lineHeight: { xs: '2.6rem', md: '4.45rem' },
    letterSpacing: { xs: '0.026rem', md: '0.044rem' },
    padding: { xs: '1.8rem 1.6rem', md: '2.2rem' },
  },
};

const backgroundColors = {
  black: 'black',
  white: 'white',
  purple: 'purple400',
  black300: 'black300',
};

const fontColors = {
  black: 'white',
  white: 'black',
  purple: 'black',
};

const ThreadsEventButton: React.FC<ThreadsEventButtonProps> = ({
  children,
  color = 'black',
  size = '',
  href = '',
  trackingId = '',
  sx,
  component = null,
  onClick = undefined,
  tabIndex = 0,
  ...props
}) => {
  const customStyles = buttonSizes[size] || '';
  const track = useTrack();

  const handleClick = (event) => {
    if (trackingId) {
      track({
        type: trackingActions.BUTTON_CLICKED,
        payload: { trackingId, event, currentTarget: event.currentTarget },
      });
    }
  };

  return (
    <StyledButton
      slots={{ root: component || 'a' }}
      href={href}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick();
        } else {
          handleClick(e);
        }
      }}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: backgroundColors[color],
        color: fontColors[color],
        padding: '1.2rem',
        textTransform: 'uppercase',
        border: '2px solid black',
        fontWeight: '600',
        borderRadius: { xs: '44px', md: '82px' },
        cursor: 'pointer',
        '&:hover, :focus': {
          color: 'black',
          backgroundColor: '#DBFF00',
        },
        '&:active': {
          color: 'black',
          backgroundColor: '#D5E41A',
        },
        transition: 'background-color 0.5s ease-in-out, color 0.5s ease-in-out',
        ...customStyles,
        ...sx,
      }}
      tabIndex={tabIndex || 0}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default ThreadsEventButton;
