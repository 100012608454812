import React, { useEffect } from 'react';
import { GetStaticProps } from 'next';

// template
import { MainTemplate } from 'src/templates';
import { Locales, isDevelopment } from 'src/lib/constants';

// helpers
import { marketingCmsClient } from 'src/lib/contentful-client';
import { PAGE } from 'src/lib/trackingEvents';

// components
import { Wrapper } from 'src/containers';
import { Box, Column, Spacer } from 'src/components-v2/Layout';
import {
  HeroWithVideo,
  TwoColumnVideoLayout,
  Features,
  TwoColumnVideoCards,
} from 'src/sections/ThreadsEvent';
import { Image } from 'src/components-v2/Media';
import { Paywall, PaywallProvider } from 'src/components/Surfaces';

const Index = ({ locale = Locales.EN_US, fields }) => {
  // handle hash anchors
  useEffect(() => {
    const anchor = window.location.hash;
    if (anchor) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1);
      const id = `${anchor.substring(1)}`;
      const node = document.querySelector(`#${id}`);
      if (node) {
        setTimeout(() => {
          node.scrollIntoView({ behavior: 'smooth' });
        }, 300);
      }
    }
  }, []);

  return (
    <MainTemplate
      {...metaData}
      locale={locale}
      hasForeignContentNotification={false}
      sx={{ bgcolor: 'black300' }}
      openGraphImage='//images.ctfassets.net/ss5kfr270og3/2ZEammPn3g3GDv4TLUTPep/96a544d08189d948a7c185e7d0e50d82/Post-Event_OG.png'
    >
      <PaywallProvider
        cookieName='threadsEvent23'
        unlockKey={fields?.secretKey}
        hasInvertedPaywallLogic
      >
        <HeroWithVideo {...HeroData} />
        <Spacer sx={{ height: { xs: '120px', sm: '220px', md: '200px' } }} />
        <Box id='what-we-announced'>
          <TwoColumnVideoCards {...ProductsDemoData} />
        </Box>
        <Box id='customer-stories'>
          <TwoColumnVideoLayout {...TwoColumnLayoutData} />
        </Box>
        <Spacer
          sx={{
            height: { xs: '74px', sm: '130px', lg: '150px' },
          }}
        />
        <Box id='new-features-and-products'>
          <Features {...featuresData} />
        </Box>
        <Spacer sx={{ height: { xs: '50px', sm: '118px', lg: '150px' } }} />
        <Wrapper>
          <Column sx={{ margin: '0 auto' }}>
            <Image
              src={footerLogo.src + footerLogo.desktopImg}
              alt='Plaid Threads event logo'
              width='512'
              height='104'
              isLazy={true}
              sources={[
                {
                  media: '(max-width: 639px)',
                  srcSet: `${footerLogo.src + footerLogo.mobileImg}`,
                },
                {
                  media: '(min-width: 640px)',
                  srcSet: `${footerLogo.src + footerLogo.desktopImg}`,
                },
              ]}
            />
          </Column>
        </Wrapper>
        <Spacer
          sx={{ height: { xs: '10rem', sm: '12.5rem', lg: '13.5rem' } }}
        />
        <Paywall
          fields={fields}
          trackingId={PAGE.THREADS_EVENT_23}
          hasGradient={false}
        >
          <></>
        </Paywall>
      </PaywallProvider>
    </MainTemplate>
  );
};

export default Index;

export const getStaticProps: GetStaticProps = async () => {
  // fetch the post event page paywall
  const id = isDevelopment
    ? '6MKUjj0E4NBkO4xV7TAHXA'
    : '31yTmsaO1CykxXXzyH20Ln';
  const entry = await marketingCmsClient.getEntry(id, {
    include: 10,
  });
  return { props: entry };
};

// data
const metaData = {
  'meta-title': 'Threads 2023 On Demand',
  'meta-description':
    "Plaid's new network-based products help customers simplify onboarding, fight fraud, and accelerate payments. Watch Threads 2023 to learn more.",
  noIndex: false,
};

const HeroData = {
  heading: 'Watch Threads 2023 on demand',
  description:
    'Solve long-standing problems in finance with our new network-based products–built to help you simplify onboarding, fight fraud, and accelerate payments.',
  videoUrl: 'https://www.youtube.com/watch?v=bcmMfiWfTy0',
  image: {
    src:
      'https://images.ctfassets.net/ss5kfr270og3/59b7FxtB4HBJazwwFlwbGL/eab65a2fb0533374e729371eeda2b958/Date_and_Wordmark.png',
    alt: 'Plaid Threads event logo',
  },
};

const ProductsDemoData = {
  heading: 'Here’s what we announced',
  cards: [
    {
      label: 'Simplify Onboarding',
      anchorId: 'identity-verification',
      heading: 'Identity Verification',
      description:
        'Say hello to our new “one-click” identity verification experience. Offer a fast and secure onboarding experience to sign up new users at scale.',
      href: '/products/identity-verification/',
      video: {
        url: 'https://www.youtube.com/watch?v=T2rmaB0IGNA',
        coverImg:
          'https://images.ctfassets.net/ss5kfr270og3/5SzAKK2EYJDNUXDmSwRfFY/09fb356e515260b449ef09416c2e536e/IDV_Thumb.png',
      },
    },
    {
      isReversed: true,
      label: 'Simplify Onboarding',
      anchorId: 'hosted-link',
      heading: 'Hosted Link',
      description:
        'Give users the ability to connect their financial accounts with Link on-the-go. With minimal integration required and no need for a front-end app.',
      href: '/docs/assets/waitlist/hosted-link/',
      video: {
        url: 'https://www.youtube.com/watch?v=t5ZYArXOo4w',
        coverImg:
          'https://images.ctfassets.net/ss5kfr270og3/17b4GlbzJz0ZMFiRr9aOXz/d2ec56919a28460e33c4fc697291b7b8/Hosted_Link_Thumb.png',
      },
    },
    {
      label: 'Fight Fraud',
      anchorId: 'beacon',
      heading: 'Beacon',
      description:
        'No one can solve fraud alone. Meet Beacon, a new anti-fraud network designed to help you stop fraud from proliferating across the ecosystem.',
      href: '/products/beacon/',
      video: {
        url: 'https://www.youtube.com/watch?v=5T8tc9N905M',
        coverImg:
          'https://images.ctfassets.net/ss5kfr270og3/91EV0yQblxd0SSosewCGR/0f79b23e4ae6750b01a297af9f1d4b2d/Beacon_Thumb.png',
      },
    },
    {
      isReversed: true,
      label: 'Accelerate Payments',
      anchorId: 'signal',
      heading: 'Signal',
      description:
        'Analyze risk like a data scientist. With the Signal Dashboard, now you can make smarter, faster risk decisions based on powerful network-based insights.',
      href: '/products/signal/',
      video: {
        url: 'https://www.youtube.com/watch?v=uOMy_QvvjQQ',
        coverImg:
          'https://images.ctfassets.net/ss5kfr270og3/2xAStYt7NHYn3c4ZbYoDZi/7a5f21078ba0227298d7f387e998b607/Signal_Thumb.png',
      },
    },
    {
      label: 'Accelerate Payments',
      anchorId: 'transfer',
      heading: 'Transfer',
      description:
        'The future of payments is here. Transfer gives you the multi-rail payment stack you need to navigate new rails and offer best-in-class conversion.',
      href: '/transfer/',
      video: {
        url: 'https://www.youtube.com/watch?v=1dt3BTHu2Jw',
        coverImg:
          'https://images.ctfassets.net/ss5kfr270og3/2viHyzsjsTaxCxpfJVJ8CX/159cb7eebfabb0534d0fa7838a6bb698/Transfer_Thumb.png',
      },
    },
  ],
};

const TwoColumnLayoutData = {
  heading: 'Customer Stories',
  columns: [
    {
      video: {
        url: 'https://www.youtube.com/watch?v=NSZoazZkqxY',
        coverImg:
          'https://images.ctfassets.net/ss5kfr270og3/KRsAwld8zExr5tNJaCC6T/b5e8fbc46dec62d789a9cdc63bce9e9d/Esusu_Thumb.png',
      },
      anchorId: 'esusu',
      heading: 'Esusu',
      description:
        'Millions of Americans are “credit invisible”. Hear how Esusu helps members build credit, find financial stability, and bridge the racial wealth gap.',
    },
    {
      video: {
        url: 'https://www.youtube.com/watch?v=Zzrp9RFKHEs',
        coverImg:
          'https://images.ctfassets.net/ss5kfr270og3/2he45y32O0ylNsOEcU8zik/2dc1f099cb631871a35e4fa4ee695c85/RocketMoney_Thumb.png',
      },
      anchorId: 'rocketmoney',
      heading: 'Rocket Money',
      description:
        'From a startup to more than 3 million members. Hear how Rocket Money (formerly Truebill) built one of the industry’s leading personal finance apps.',
    },
  ],
};

const featuresData = {
  heading: 'More new features and products',
  description:
    'Beyond Threads 2023 announcements, we’re making it easier for you to reach more users in more places with new features like transaction enrichment, new credit categories, localized products, and more.',
  rows: [
    [
      {
        heading: 'Enrich your Transactions data',
        description:
          'To make sense of messy Transactions data, <a href="https://plaid.com/products/enrich/">Enrich</a> delivers insights generated from your card products, bank accounts, and other non-Plaid sources.',
      },
      {
        heading: 'Instant Payouts with Transfer',
        description:
          'RTP is now supported on Transfer. With <a href="https://plaid.com/blog/plaid-transfer-instant-payouts/">Instant Payouts</a>, a multi-rail payout solution, you can send funds instantly to any eligible person or business.',
      },
    ],
    [
      {
        heading: 'ID Verification & Income go global',
        description:
          'Income is now available in the UK and Europe (and quickly gaining momentum with customers). <a href="https://plaid.com/en-gb/products/identity-verification/">Identity Verification</a> is also now available in beta in the UK.',
      },
      {
        heading: '100% Migration to APIs',
        description:
          'We <a href="https://plaid.com/blog/api-progress-update/">migrated 100% of traffic</a> to APIs for Capital One, JPMorgan Chase, USAA, and Wells Fargo with full migration to RBC, Citibank, M&T, and more on the way.',
      },
    ],
    [
      {
        heading: 'More Investments coverage',
        description:
          'You asked, and we delivered! <a href="https://plaid.com/products/investments/">Investments coverage</a> is now available for Truist, Optum Financial, and Prudential Retirement.',
      },
      {
        heading: 'Verify with bank PDF statements',
        description:
          'We just made it even easier to <a href="https://plaid.com/docs/assets/waitlist/statements/">retrieve a PDF copy</a> of end users’ statements for loan verifications, rental application reviews, and more.',
      },
    ],
  ],
};

const footerLogo = {
  src:
    'https://images.ctfassets.net/ss5kfr270og3/5lUwbp5k6cnbSNwYK9Ksj1/84cd2ac2a1f3bc97d660bcb475c9daf7/Asset_2_1x_2.png',
  desktopImg: '?fm=webp&w=1024&fit=fill&q=70',
  mobileImg: '?fm=webp&w=512&fit=fill&q=100',
};
