import React, { FC } from 'react';
import { TwoColumnVideoCardsProps } from './TwoColumnVideoCards.types';

// components
import { Typography } from 'src/components-v2/DataDisplay';
import { Box, Column, Container, Section, Row } from 'src/components-v2/Layout';
import { TwoColumnVideoCard } from 'src/sections/ThreadsEvent';
import { Image } from 'src/components-v2/Media';

const BackgroundImage = {
  src:
    'https://images.ctfassets.net/ss5kfr270og3/7d3iinpcRprTBoaOMXlvNH/e0075921a7480d2742e32822febeee88/threads-white-texture.png',
  desktopImg: '?fm=webp&w=1578&h=500&fit=fill&q=1',
  mobileImg: '?fm=webp&w=640&h=202&fit=fill&q=1',
};

const TwoColumnVideoCards: FC<TwoColumnVideoCardsProps> = ({
  heading,
  cards,
}) => {
  return (
    <Section>
      <Container>
        <Row>
          <Column xs={22} xsOffset={1}>
            <Section component='header'>
              <Typography
                variant='h2'
                theme='post-threads-two-column-video-card'
                sx={{ textAlign: 'center', mb: { xs: '3rem', sm: '5rem' } }}
              >
                {heading}
              </Typography>
            </Section>
          </Column>
        </Row>
        <Row>
          <Column xs={24} sm={22} smOffset={1} sx={{ pb: '0' }}>
            <>
              {cards.map((data, i) => {
                return (
                  <Box id={data.anchorId} key={`video-card-${i}`}>
                    <TwoColumnVideoCard {...data} />
                  </Box>
                );
              })}
              <Box
                sx={{
                  position: 'relative',
                  height: { xs: '74px', sm: '130px', lg: '150px' },
                  overflow: 'hidden',
                }}
              >
                <Image
                  sx={{
                    position: 'absolute',
                    top: { xs: '-15px', sm: '-40px' },
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }}
                  isLazy={true}
                  width='789px'
                  height='250px'
                  src={BackgroundImage.src + BackgroundImage.desktopImg}
                  alt=''
                  sources={[
                    {
                      media: '(max-width: 639px)',
                      srcSet: `${
                        BackgroundImage.src + BackgroundImage.mobileImg
                      }`,
                    },
                    {
                      media: '(min-width: 640px)',
                      srcSet: `${
                        BackgroundImage.src + BackgroundImage.desktopImg
                      }`,
                    },
                  ]}
                />
              </Box>
            </>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default TwoColumnVideoCards;
