import React, { FC } from 'react';
import {
  VideoSectionColumnProps,
  TwoColumnVideoLayoutProps,
} from './TwoColumnVideoLayout.types';
import useMeasure from 'react-use-measure';

import {
  Box,
  Container,
  Section,
  Row,
  Column,
  Stack,
} from 'src/components-v2/Layout';
import { Typography } from 'src/components-v2/DataDisplay';
import { VideoPlayer } from 'src/components-v2/Media/';
import { PlayButton } from 'src/components-v2/Inputs';
import { WatchNowButton } from 'src/sections/ThreadsEvent';
import { usePaywallProvider } from 'src/components/Surfaces';

const VideoSectionColumn: FC<VideoSectionColumnProps> = ({
  video,
  heading = '',
  description = '',
}) => {
  const [playerRef, playersRefBounds] = useMeasure();
  const {
    setIsModalOpen,
    isContentUnlocked,
    hasPaywall,
  } = usePaywallProvider();

  return (
    <Section component='article' sx={{ mb: '6rem', position: 'relative' }}>
      {hasPaywall && !isContentUnlocked && (
        <Stack
          sx={{
            mb: { xs: '2rem', lg: '2.5rem' },
            border: '1px solid black',
            position: 'absolute',
            height: playersRefBounds.height,
            width: '100%',
            zIndex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <WatchNowButton
            onClick={() => {
              return setIsModalOpen(true);
            }}
          />
        </Stack>
      )}
      <VideoPlayer
        ref={playerRef}
        sx={{
          mb: { xs: '2rem', lg: '2.5rem' },
          border: '1px solid black',
          height: { xs: '215px', sm: '350px' },
        }}
        url={video.url}
        playing={true}
        playIcon={!hasPaywall || isContentUnlocked ? <PlayButton /> : <></>}
        light={`${video.coverImg + '?fm=webp&q=70'}` || true}
      />
      <Section component='header'>
        <Typography
          variant='h3'
          theme='post-threads-two-column-video-layout'
          sx={{ mb: '1.5rem' }}
        >
          {heading}
        </Typography>
      </Section>
      <Typography variant='p' theme='post-threads-two-column-video-layout'>
        {description}
      </Typography>
    </Section>
  );
};

const TwoColumnVideoLayout: FC<TwoColumnVideoLayoutProps> = ({
  heading,
  columns,
}) => {
  return (
    <Section>
      <Container>
        {heading && (
          <Row>
            <Column xs={22} xsOffset={1}>
              <Section component='header'>
                <Typography
                  variant='h2'
                  theme='post-threads-two-column-video-layout'
                  sx={{
                    mb: '6rem',
                    textAlign: 'center',
                    position: 'relative',
                    zIndex: 1,
                  }}
                >
                  {heading}
                </Typography>
              </Section>
            </Column>
          </Row>
        )}
        <Row>
          {columns.map((column, i) => {
            return (
              <Column
                key={`column-${i}`}
                xs={22}
                xsOffset={1}
                lg={10}
                lgOffset={i === 0 ? 1 : 2}
              >
                <Box id={column.anchorId}>
                  <VideoSectionColumn
                    video={column.video}
                    heading={column.heading}
                    description={column.description}
                  />
                </Box>
              </Column>
            );
          })}
        </Row>
      </Container>
    </Section>
  );
};

export default TwoColumnVideoLayout;
