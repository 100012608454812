import React, { FC } from 'react';
import {
  VideoColumnProps,
  TextColumnProps,
  TwoColumnVideoCardProps,
} from './TwoColumnVideoCard.types';
import useMeasure from 'react-use-measure';
import styles from 'src/components/Inputs/Link/Link.module.scss';

import { Row, Column, Section, Stack } from 'src/components-v2/Layout';
import { Typography } from 'src/components-v2/DataDisplay';
import { VideoPlayer } from 'src/components-v2/Media';
import { ThreadsEventButton, WatchNowButton } from 'src/sections/ThreadsEvent';
import { PlayButton } from 'src/components-v2/Inputs';
import { Link } from 'src/components/Inputs';
import { usePaywallProvider } from 'src/components/Surfaces';

const TextColumn: FC<TextColumnProps> = ({
  label,
  heading,
  description,
  href,
}) => {
  return (
    <Column xs={20} xsOffset={1} lg={8} lgOffset={1}>
      <Link href={href}>
        <a>
          <Section component='header'>
            <Typography
              variant='h6'
              component='p'
              theme='post-threads-two-column-video-card'
              sx={{ mb: '1.3rem' }}
            >
              {label}
            </Typography>
            <Typography
              variant='h3'
              theme='post-threads-two-column-video-card'
              sx={{ mb: '2rem' }}
            >
              {heading}
            </Typography>
            <Typography
              variant='p'
              theme='post-threads-two-column-video-card'
              sx={{ mb: '3rem' }}
            >
              {description}
            </Typography>
          </Section>
          <span className={styles.cta}>
            <ThreadsEventButton
              tabIndex={-1}
              component='span'
              color='black300'
              sx={{
                textTransform: 'none',
                width: '156px',
                py: '1rem',
                fontSize: '1.8rem',
                letterSpacing: '0.008rem',
                border: 1,
                '&:hover, :focus, :active': {
                  color: 'white',
                  backgroundColor: 'black',
                },
              }}
            >
              Learn More
            </ThreadsEventButton>
          </span>
        </a>
      </Link>
    </Column>
  );
};

const VideoColumn: FC<VideoColumnProps> = ({ video }) => {
  const [playerRef, playersRefBounds] = useMeasure();
  const {
    setIsModalOpen,
    isContentUnlocked,
    hasPaywall,
  } = usePaywallProvider();

  return (
    <Column
      xs={24}
      sm={22}
      smOffset={1}
      lg={13}
      lgOffset={1}
      sx={{ px: '0', position: 'relative' }}
    >
      {hasPaywall && !isContentUnlocked && (
        <Stack
          sx={{
            mb: { xs: '2rem', sm: '2.5rem', lg: '0' },
            border: '1px solid black',
            position: 'absolute',
            height: playersRefBounds.height,
            width: '100%',
            zIndex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <WatchNowButton
            onClick={() => {
              return setIsModalOpen(true);
            }}
          />
        </Stack>
      )}
      <VideoPlayer
        ref={playerRef}
        sx={{
          mb: { xs: '2rem', sm: '2.5rem', lg: '0' },
          border: '1px solid black',
          height: { xs: '245px', sm: '350px' },
        }}
        url={video.url}
        playing={true}
        playIcon={!hasPaywall || isContentUnlocked ? <PlayButton /> : <></>}
        light={`${video.coverImg + '?fm=webp&q=70'}` || true}
      />
    </Column>
  );
};

const TwoColumnVideoCard: FC<TwoColumnVideoCardProps> = ({
  label = '',
  heading = '',
  description = '',
  video,
  href = '',
  isReversed = false,
}) => {
  return (
    <Row
      sx={{
        flexDirection: {
          xs: isReversed ? 'column' : 'column-reverse',
          lg: 'row',
        },
        alignItems: { lg: 'center' },
        border: '1px solid black',
        borderRadius: '20px',
        px: '0',
        py: '4rem',
        mt: '0',
        mb: '-1px',
        position: 'relative',
        zIndex: 1,
      }}
    >
      {isReversed ? (
        <>
          <VideoColumn video={video} />
          <TextColumn
            label={label}
            heading={heading}
            description={description}
            href={href}
          />
        </>
      ) : (
        <>
          <TextColumn
            label={label}
            heading={heading}
            description={description}
            href={href}
          />
          <VideoColumn video={video} />
        </>
      )}
    </Row>
  );
};

export default TwoColumnVideoCard;
